import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';

import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import PropTypes from 'prop-types';

//chart type
import React, { useLayoutEffect } from 'react';
import { InspectionStatusesMap } from 'constants/colors';

export const Pie = ({ chartID, cda }) => {
  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);

    root.setThemes([am5themes_Animated.new(root)]);

    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(100),
      })
    );

    // Create series

    var series3 = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'size',
        categoryField: 'type',
        alignLabels: false,
      })
    );

    // Configuring slices
    series3.slices.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
    });

    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'size',
        categoryField: 'type',
        alignLabels: false,
      })
    );

    // Configuring slices
    series.slices.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
    });

    var series2 = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: 'size',
        categoryField: 'type',
        tooltipDescriptionField: 'tooltipDescription',
        alignLabels: false,
      })
    );

    // Configuring slices
    series2.slices.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
    });

    series.labels.template.setAll({
      fontSize: 9,
      text: '{category}',
      radius: -49,
      centerX: am5.percent(100),
      fontWeight: 'bold',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
    });
    // obliquo
    // series.labels.template.setAll({
    //   fontSize: 8,
    //   text: '{category}',
    //   radius: 0,
    //   textType: 'radial',
    //   centerX: am5.percent(100),
    //   fontWeight: 'bold',
    //   fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
    // });
    series2.labels.template.setAll({
      fontSize: 10,
      text: '{category}',
      textType: 'radial',
      radius: -20,
      bent: true,
      padding: 0,
      fontWeight: 'bold',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
    });

    series3.labels.template.setAll({
      fontSize: 15,
      text: '{category}',
      radius: -60,
      bent: true,
      padding: 0,
      fontWeight: 'bold',
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
    });

    var tooltip = am5.Tooltip.new(root, {
      labelText: '{tooltipDescription}',
    });

    tooltip.get('background').setAll({
      stroke: am5.color(0x000000),
      strokeOpacity: 0.8,
    });
    series.set('tooltip', tooltip);
    series2.set('tooltip', tooltip);
    series3.set('tooltip', tooltip);

    // Disabling
    series.ticks.template.set('visible', false);
    series2.ticks.template.set('visible', false);
    series3.ticks.template.set('visible', false);

    series.slices.template.set('toggleKey', 'none');
    series2.slices.template.set('toggleKey', 'none');
    series3.slices.template.set('toggleKey', 'none');

    series.slices.template.adapters.add('fill', function (fill, target) {
      switch (target.dataItem.dataContext.class) {
        case 'MEDIA':
          return am5.color(InspectionStatusesMap[3].labelColor);
        case 'MEDIO-BASSA':
          return am5.color(InspectionStatusesMap[2].labelColor);
        case 'MEDIO-ALTA':
          return am5.color(InspectionStatusesMap[4].labelColor);
        case 'BASSA':
          return am5.color(InspectionStatusesMap[1].labelColor);
        case 'ALTA':
          return am5.color(InspectionStatusesMap[5].labelColor);
        default:
          return am5.color(InspectionStatusesMap[6].labelColor);
      }
    });
    series2.slices.template.adapters.add('fill', function (fill, target) {
      switch (target.dataItem.dataContext.class) {
        case 'MEDIA':
          return am5.color(InspectionStatusesMap[3].labelColor);
        case 'MEDIO-BASSA':
          return am5.color(InspectionStatusesMap[2].labelColor);
        case 'MEDIO-ALTA':
          return am5.color(InspectionStatusesMap[4].labelColor);
        case 'BASSA':
          return am5.color(InspectionStatusesMap[1].labelColor);
        case 'ALTA':
          return am5.color(InspectionStatusesMap[5].labelColor);
        default:
          return am5.color(InspectionStatusesMap[6].labelColor);
      }
    });
    series3.slices.template.adapters.add('fill', function (fill, target) {
      switch (target.dataItem.dataContext.class) {
        case 'MEDIA':
          return am5.color(InspectionStatusesMap[3].labelColor);
        case 'MEDIO-BASSA':
          return am5.color(InspectionStatusesMap[2].labelColor);
        case 'MEDIO-ALTA':
          return am5.color(InspectionStatusesMap[4].labelColor);
        case 'BASSA':
          return am5.color(InspectionStatusesMap[1].labelColor);
        case 'ALTA':
          return am5.color(InspectionStatusesMap[5].labelColor);
        default:
          return am5.color(InspectionStatusesMap[6].labelColor);
      }
    });
    series.data.setAll([
      {
        type: 'Sismica',
        size: 1,
        class: cda?.sismica?.cda,
        tooltipDescription: 'CdA Sismica',
      },
      {
        type: 'Idraulica',
        size: 1,
        class: cda?.idraulica?.cda,
        tooltipDescription: 'CdA Idraulica',
      },
      {
        type: 'Frane',
        size: 1,
        class: cda?.frana?.cda,
        tooltipDescription: 'CdA Frane',
      },
      {
        type: 'Struttura',
        size: 1,
        class: cda?.strutturale?.cda,
        tooltipDescription: 'CdA Struttura',
      },
    ]);

    series3.data.setAll([
      {
        type: 'CdA',
        size: 1,
        class: cda?.cda,
        tooltipDescription: 'CdA',
      },
    ]);

    series2.data.setAll([
      {
        type: 'E',
        size: 1,
        class: cda?.sismica?.e,
        tooltipDescription: 'Esposizione',
      },
      {
        type: 'V',
        size: 1,
        class: cda?.sismica?.v,
        tooltipDescription: 'Vulnerabilità',
      },
      {
        type: 'P',
        size: 1,
        class: cda?.sismica?.p,
        tooltipDescription: 'Pericolosità',
      },
      {
        type: 'EL',
        size: 1,
        class: cda?.idraulica?.el,
        tooltipDescription: 'Erosione Localizzata',
      },
      {
        type: 'EG',
        size: 1,
        class: cda?.idraulica?.eg,
        tooltipDescription: 'Erosione Generalizzata',
      },
      {
        type: 'S',
        size: 1,
        class: cda?.idraulica?.s,
        tooltipDescription: 'Sormonto',
      },
      {
        type: 'E',
        size: 1,
        class: cda?.frana?.e,
        tooltipDescription: 'Esposizione',
      },
      {
        type: 'V',
        size: 1,
        class: cda?.frana?.v,
        tooltipDescription: 'Vulnerabilità',
      },
      {
        type: 'S',
        size: 1,
        class: cda?.frana?.s,
        tooltipDescription: 'Suscettibilità',
      },
      {
        type: 'E',
        size: 1,
        class: cda?.strutturale?.e,
        tooltipDescription: 'Esposizione',
      },
      {
        type: 'V',
        size: 1,
        class: cda?.strutturale?.v,
        tooltipDescription: 'Vulnerabilità',
      },
      {
        type: 'P',
        size: 1,
        class: cda?.strutturale?.p,
        tooltipDescription: 'Pericolosità',
      },
    ]);
  }, [chartID]);

  return <div style={{ width: '100%', height: '100%' }} id={chartID}></div>;
};

Pie.propTypes = {
  chartID: PropTypes.string,
  cda: PropTypes.object,
};
